<template>
    <div class="CulteraMax">
        <!-- 星空背景 -->
        <div class="stars" ref="starsRef">
            <div v-for="(item,index) in statrsCount" :key="index" class="star">

            </div>
        </div>
        <!--头部导航 -->
        <div class="CulteraNavi">
            <!-- 点击返回首页 -->
            <div class="CulteraHomePage" @click="home()">
                <img src="../../assets/CulturalHomePage/PomePage.png" alt="" style="width:100%;height:100%;" />
            </div>
            <!-- 昵称介绍 -->
            <div class="CulteraName">
                <span style="margin-left: -4%">数字文创馆</span>
            </div>
            <!-- 点击进入个人中心 -->
            <div class="CulteraPersonal" @click="personage()">
                <img src="../../assets/CulturalHomePage/personal.png" alt="" style="width: 100%; height: 100%" />
            </div>
        </div>
        <!-- 内容 -->
        <div class="ConBox">
            <div class="ZhuTu">
                <div style="width: 100%;height: 80%;">
                    <!-- 悬浮图标 -->
                    <div class="LeftSuspensionBox">
                        <div class="LeftSuspensionBoxTop">
                            <div class="container">
                                <div class="wrap">
                                    <div class="cube">
                                        <div class="out-front">
                                            <img id="out-front" src="../../assets/Login/LoginQiuOne.png" alt="">
                                        </div>
                                        <div class="out-back">
                                            <img id="out-back" src="../../assets/Login/LoginQiuTwo.png" alt="">
                                        </div>
                                        <div class="out-left">
                                            <img id="out-left" src="../../assets/Login/LoginQiuthere.png" alt="">
                                        </div>
                                        <div class="out-right">
                                            <img id="out-right" src="../../assets/Login/LoginQiuFour.png" alt="">
                                        </div>
                                        <div class="out-top">
                                            <img id="out-top" src="../../assets/Login/LoginQiuFive.png" alt="">
                                        </div>
                                        <div class="out-bottom">
                                            <img id="out-bottom" src="../../assets/Login/LoginQiuSix.png" alt="">
                                        </div>

                                        <span class="in-front">
                                            <img id="in-front" src="../../assets/Login/LoginQiuOneNei.png" alt="" />
                                        </span>
                                        <span class="in-back">
                                            <img id="in-back" src="../../assets/Login/LoginQiuTwoNei.png" alt="" />
                                        </span>
                                        <span class="in-left">
                                            <img id="in-left" src="../../assets/Login/LoginQiuThereNei.png" alt="" />
                                        </span>
                                        <span class="in-right">
                                            <img id="in-right" src="../../assets/Login/LoginQiuFourNei.png" alt="" />
                                        </span>
                                        <span class="in-top">
                                            <img id="in-top" src="../../assets/Login/LoginQiuFiveNei.png" alt="" />
                                        </span>
                                        <span class="in-bottom">
                                            <img id="in-bottom" src="../../assets/Login/LoginQiuSixNei.png" alt="" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Xiang">
                    <div style="width: 90%;color: rgb(173, 178, 194);margin-top: 4vh;">
                        <marquee direction="up" behavior="scroll" scrollamount="1" style="height: 15vh;margin: 0.5vh 0;line-height: 3.5 vh;">
                            {{ current.summary }}
                        </marquee>
                    </div>
                </div>
            </div>
            <div class="LieBiao">
                <div Class="LieBiaoHead">
                    <span>文创作品</span>
                    <span style="color:#fdbd00;cursor: pointer;" @click="changePage">换一批</span>
                </div>
                <list ref="wczp" :Father="Father"></list>
            </div>
        </div>
        <div @click="BigImgShow = false" v-if="BigImgShow" style="width: 100%;height: 100%;background-color: rgba(0,0,0,0.5);position: fixed;top: 0;left: 0;z-index: 99; backdrop-filter: blur(10px);cursor: pointer;">
            <div style="position: fixed;top: 0;left: 0;z-index: 999;display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;">
                <img :src="current.logoUrl" style="width: 100vh;height: 70vh;" alt="">
            </div>
        </div>
    </div>
</template>
    
<script>
import $ from 'jquery'
import list from './components/list.vue'
export default {
    components: { list },
    data () {
        return {
            BigImgShow: false,
            statrsCount: 1000,//星星数量
            distance: 600,//间距
            active: 0,
            LieBiaoImgIndex: 0,
            imgList: [],
            imgTotalPage: 0,
            imgPage: 1,
            current: {}
        };
    },

    created () {
        document.addEventListener('fullscreenchange', this.handleFullscreenChange)
        document.addEventListener('mozfullscreenchange', this.handleFullscreenChange)
        document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange)
    },
    mounted () {
        this.XingKo()
    },

    methods: {
        Father () {
            // 大概率没数据 这里留着调试看有没有数据
            $('#out-front').attr("src", this.current.imgs[0].resourceUrl);
            $('#out-back').attr("src", this.current.imgs[1].resourceUrl);
            $('#out-left').attr("src", this.current.imgs[2].resourceUrl);
            $('#out-right').attr("src", this.current.imgs[3].resourceUrl);
            $('#out-top').attr("src", this.current.imgs[4].resourceUrl);
            $('#out-bottom').attr("src", this.current.imgs[5].resourceUrl);

            $('#in-front').attr("src", this.current.imgs[5].resourceUrl);
            $('#in-back').attr("src", this.current.imgs[4].resourceUrl);
            $('#in-left').attr("src", this.current.imgs[3].resourceUrl);
            $('#in-right').attr("src", this.current.imgs[2].resourceUrl);
            $('#in-top').attr("src", this.current.imgs[1].resourceUrl);
            $('#in-bottom').attr("src", this.current.imgs[0].resourceUrl);
        },
        // 换一换
        changePage () {
            this.$refs.wczp.nextPage()
        },
        // 去详情
        goDetail () {
            this.$router.push({
                path: '/CulturalDetail',
                query: {
                    id: this.current.culturalId
                }
            });
        },

        //  点击跳转首页
        home () {
            this.$router.push('/')
        },
        // 点击跳转个人中心
        personage () {
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if (this.$cookies.get("token") == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
        XingKo () {
            let starNodes = Array.from(this.$refs.starsRef.children);
            starNodes.forEach((item) => {
                let speed = 0.2 + Math.random() * 1;
                let thisDistance = this.distance + Math.random() * 300;
                item.style.transformOrigin = `0 0 ${thisDistance}px`;
                item.style.transform =
                    `
                translate3d(0,0,-${thisDistance}px)
                rotateY(${Math.random() * -360}deg)
                rotateX(${Math.random() * -20}deg)
                scale(${speed},${speed})`;
            });
        },
        // 获取视频列表
        // getVideoList () {
        //     let t = this
        //     var axios = require('axios');
        //     var config = {
        //         method: 'post',
        //         url: this.$Schttp + '/vtp/app/cultural/videoPage',
        //         headers: {},
        //         data: {
        //             pageSize: 9,
        //             pageNum: 1,
        //         }
        //     };
        //     axios(config)
        //         .then(function (res) {
        //             console.log(res.data)
        //             t.videoList = res.data.rows
        //             t.current = t.videoList[0]
        //             t.videoTotalPage = Math.ceil(res.data.total / 9) || 1
        //         })
        // },
        // 换一换
        // nextPage () {
        //     if (this.active == 0) {
        //         if (this.imgTotalPage > this.imgPage) {
        //             this.imgPage++
        //         } else {//如果是最后一页就回到第一页
        //             this.imgPage = 1
        //         }
        //         this.getImgList()
        //     } else {
        //         if (this.videoTotalPage > this.videoPage) {
        //             this.videoPage++
        //         } else {
        //             this.videoPage = 1
        //         }
        //         this.getVideoList()
        //     }
        // },
        //点击视频全屏播放
        HandleFullScreenOne (e) {
            this.launchFullscreen(e.target)
        },
        // 进入全屏
        launchFullscreen (element) {
            //此方法不可以在異步任務中執行，否則火狐無法全屏
            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
            } else if (element.oRequestFullscreen) {
                element.oRequestFullscreen()
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen()
            } else {
                var docHtml = document.documentElement
                var docBody = document.body
                var videobox = element
                var cssText = 'width:100%;height:100%;overflow:hidden;'
                docHtml.style.cssText = cssText
                docBody.style.cssText = cssText
                videobox.style.cssText =
                    cssText + ';' + 'margin:0px;padding:0px;'
                document.IsFullScreen = true
            }
        },
        HandleExitFullScreenOne (e) {
            this.exitFullscreen(e.target)
        },
        exitFullscreen (element) {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            } else {
                var docHtml = document.documentElement
                var docBody = document.body
                var videobox = element
                docHtml.style.cssText = ''
                docBody.style.cssText = ''
                videobox.style.cssText = ''
                document.IsFullScreen = false
            }
        },
        handleFullscreenChange () {
            const video = this.$refs.myVideo
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                video.pause()
            }
        },
    },
    beforeUnmount () {
        // 销毁监听视频
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange)
        document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange)
        document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange)
    },
};
</script>
     
    
<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}
@font-face {
    font-family: 'YouShe';
    src: url('../../ZiTi/YouShe.ttf');
}
.LeftSuspensionBox {
    position: absolute;
    top: 17%;
    left: 8%;
    width: 35%;
    height: 80vh;
    cursor: pointer;
    .LeftSuspensionBoxTop {
        width: 55%;
        height: 35%;
        position: relative;
        top: 0%;
        left: 22%;
        z-index: 1;
        animation: LeftSuspensionBoxTop 3s infinite; // 反反向再运行一遍
        animation-direction: alternate;
        /* 全模块定位和总布局 用定位top/left,没有margin但不脱标调位置 */

        .container {
            position: relative;
            top: -5vh;
            left: 15vh;
        }
        /* 主要用来装功能块在容器内保证定位 相当于该功能块的padding*/

        .container .wrap {
            width: 10vh;
            height: 10vh;
            top: 1vh;
            left: 1vh;
            margin: 0;
        }
        /* 功能块 */

        .container .wrap .cube {
            width: 10vh;
            height: 10vh;
            transform-style: preserve-3d;
            /* perspective: 500px; */
            transform: rotateX(-30deg) rotateY(-80deg);
            animation: ziZhuan linear 20s infinite;
            animation-direction: alternate-reverse;
            transition: all 0.5s;
        }

        @keyframes ziZhuan {
            0% {
                transform: rotateX(0deg) rotateY(0deg);
            }
            100% {
                transform: rotateX(260deg) rotateY(360deg);
            }
        }

        .container .wrap .cube div {
            position: absolute;
            top: 0;
            left: 0;
            width: 29vh;
            height: 29vh;
            background-color: rgb(0, 183, 255);
            z-index: -1;
            transition: all 0.5s;
            opacity: 0.45;
            outline: rgb(40, 226, 240) solid thick;
            box-shadow: rgba(17, 123, 194, 0.712) 0 0 15px 15px;
        }

        .container .wrap .cube div img {
            width: 100%;
            height: 100%;
        }

        .container .wrap .cube span {
            position: absolute;
            top: 5vh;
            left: 5vh;
            display: block;
            width: 20vh;
            height: 20vh;
            outline: rgba(21, 40, 211, 0.664) solid thin;
            background-color: rgba(11, 96, 224, 0.295);
        }

        .container .wrap .cube span img {
            width: 100%;
            height: 100%;
        }

        .container .wrap .cube .out-front {
            transform: rotateY(0deg) translateZ(15vh);
        }

        .container .wrap .cube .out-back {
            transform: translateZ(-15vh);
        }
        /* //y正方向逆时针 */
        .container .wrap .cube .out-left {
            transform: rotateY(-90deg) translateZ(15vh);
        }

        .container .wrap .cube .out-right {
            transform: rotateY(90deg) translateZ(15vh);
        }
        /*  x->正-上*/

        .container .wrap .cube .out-top {
            transform: rotateX(90deg) translateZ(15vh);
        }

        .container .wrap .cube .out-bottom {
            background-color: rgba(36, 238, 80, 0.253);
            transform: rotateX(-90deg) translateZ(15vh);
        }
        .container .wrap .cube .in-left {
            transform: rotateY(-90deg) translateZ(10vh);
        }
        .container .wrap .cube .in-right {
            transform: rotateY(90deg) translateZ(10vh);
        }
        .container .wrap .cube .in-back {
            transform: translateZ(-10vh);
        }
        .container .wrap .cube .in-front {
            transform: translateZ(10vh);
        }
        .container .wrap .cube .in-top {
            transform: rotateX(90deg) translateZ(10vh);
        }
        .container .wrap .cube .in-bottom {
            transform: rotateX(-90deg) translateZ(10vh);
        }
        .container .wrap .cube:hover .out-front {
            transform: rotateY(0deg) translateZ(25vh);
        }
        .container .wrap .cube:hover .out-back {
            transform: rotateY(0deg) translateZ(-25vh);
        }
        .container .wrap .cube:hover .out-top {
            transform: rotateX(90deg) translateZ(25vh);
        }
        .container .wrap .cube:hover .out-bottom {
            transform: rotateX(-90deg) translateZ(25vh);
        }
        .container .wrap .cube:hover .out-right {
            transform: rotateY(90deg) translateZ(25vh);
        }
        .container .wrap .cube:hover .out-left {
            transform: rotateY(-90deg) translateZ(25vh);
        }
    }
    @keyframes LeftSuspensionBoxTop {
        0% {
            top: 30%;
        }
        100% {
            top: 35%;
        }
    }
}
.CulteraMax {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    min-width: 1000px;
    background: radial-gradient(
        200% 100% at bottom center,
        #101929,
        #000000,
        #1b2947
    );
    // background: radial-gradient(200% 105% at top center,#013990 10%,#000000 40%,#013990 65%,#1b2947);
    background-attachment: fixed;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @keyframes rotate {
        0% {
            transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
                rotateY(0);
        }
        100% {
            transform: perspective(400px) rotateZ(20deg) rotateX(-40deg)
                rotateY(-360deg);
        }
    }
    .stars {
        transform: perspective(500px);
        transform-style: preserve-3d;
        position: absolute;
        perspective-origin: 50% 100%;
        animation: rotate 90s infinite linear;
        left: 45%;
        bottom: 50%;
    }
    .star {
        width: 2px;
        height: 2px;
        background: #f7f7b8;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
    }

    .CulteraNavi {
        width: 80%;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        .CulteraHomePage {
            width: 7.5vh;
            height: 7vh;
            cursor: pointer;
            position: absolute;
            top: 3vh;
        }

        .CulteraName {
            width: 95%;
            padding: 2vh 0;
            margin: 1rem 0;
            background: url(../../assets/CulturalHomePage/top.png);
            background-size: 100% 100%;
            color: #ffffff;
            font-size: 4vh;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'YouShe';
        }

        .CulteraPersonal {
            width: 7.5vh;
            height: 7vh;
            cursor: pointer;
            position: absolute;
            top: 3vh;
            right: 10%;
        }
    }
    .ConBox {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .ZhuTu {
            width: 50%;
            .TXBox {
                pointer-events: none;
                position: relative;
                top: -5vh;
                left: 13vh;
                width: 65vh;
                height: 50vh;
                margin: 0 auto;
                z-index: 88;
                background-image: url(../../assets/CulturalHomePage/TXWaiBox.png);
                background-size: 100% 100%;
                transform-style: preserve-3d;
                animation: rotate 4s infinite linear; /* 8秒钟的动画循环 */
            }
            @keyframes rotate {
                0% {
                    transform: rotateY(0);
                }
                100% {
                    transform: rotateY(360deg);
                }
            }
            .ZhuCon {
                position: relative;
                top: 5vh;
                // left: -35%;
                width: 40vh;
                height: 40vh; /* 初始高度为0 */
                // border-radius: 50%;
                overflow: hidden;
                margin: 0 auto;
                animation: skew 5s infinite;
                border-radius: 0.5rem;
                img {
                    width: 100%; /* 图片宽度为容器宽度 */
                    height: 100%; /* 图片高度为容器高度 */
                    object-fit: cover; /* 图片按比例缩放并裁剪 */
                    cursor: pointer;
                    border-radius: 0.5rem;
                }
            }

            @keyframes skew {
                0% {
                    box-shadow: 0 0 0 0 #f7f7b8;
                }
                25% {
                    box-shadow: 0 0 25px 0 #f7f7b8;
                }

                50% {
                    box-shadow: 0 0 50px 0 #f7f7b8;
                }

                75% {
                    box-shadow: 0 0 25px 0 #f7f7b8;
                }

                100% {
                    box-shadow: 0 0 0 0 #f7f7b8;
                }
            }
            .DiZuo {
                // position: relative;
                // top: -15vh;
                // left: 0vh;
                margin: 0 auto;
                width: 55vh;
                height: 25vh;
                background-image: url(../../assets/CulturalHomePage/TXDiPan.png);
                background-size: 100% 100%;
            }
            .Xiang {
                // position: relative;
                // top: -15vh;
                width: 100%;
                min-height: 20vh;
                background-image: url(../../assets/CulturalHomePage/XiangBG.png);
                background-size: 100% 100%;
                // background-color: rgba(14,58,129,0.3);
                display: flex;
                justify-content: center;
                margin-top: 1vh;
            }
        }
        .LieBiao {
            width: 42%;
            background-color: rgba(14, 58, 129, 0.3);
            border: 2px solid rgba(14, 58, 129, 0.3);
            .LieBiaoHead {
                width: 92%;
                margin: 0.6rem auto;
                display: flex;
                justify-content: space-between;
                color: #fff;
                align-items: end;
                span:nth-child(1) {
                    font-size: 1.2rem;
                }
            }
        }
    }
}
</style>